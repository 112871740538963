<template lang="pug">
  .dropdown-wrapper(:class="{}")
    .dropdown(:class="dropdownClassMerger"
      @click="expandDropdown" v-click-outside="outsideClick")

      slot(name="date-picker")
      slot(name="month-picker")
      slot(name="year-picker")
      slot(name="timezone")
      slot(name="currency")
      slot(name="country-picker")

    .dropdown-cell(:class="{ expanded: expandAccount, 'expanded-dd': open }")
      .dropdown-cell__item(:class="{ 'expanded__item': open }" v-for="(item, index) in itemArray"
        :key="index" @click="currentRowHandler(item)")
        span.icell(:class="{ 'active': item[1] }") {{ renderCellItem(item) }}
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Dropdown',
  components: {},
  props: {
    itemArray: {
      type: [Array, Object]
    },
    dropdownType: {
      type: String,
    },
    open: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expandAccount: false,
    coloredStar: false,
    ddValue: null,
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
    }),
    dropdownClassMerger() {
      return {
        'datepicker': this.dropdownType === 'datepicker',
        'error': this.hasError,
      };
    },
  },
  methods: {
    currentRowHandler(item) {
      this.ddValue = item;
      this.$emit('emitDDItem', item);
    },
    expandDropdown() {
      this.expandAccount = !this.expandAccount;

      this.$emit('toggleDropdown', this.expandAccount);
    },
    outsideClick() {
      if (this.expandAccount) {
        this.expandAccount = false;

        this.$emit('toggleDropdown', this.expandAccount);
      }
    },
    renderCellItem(item) {
      if (typeof item === 'object') {
        return item.title;
      }
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
